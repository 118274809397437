<template>
  <div :key="sellKey" >
    <div class="div_title">
      <context :context=cate />
      <policies/>
    </div>
    <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true" > 
      <div class="div_title">
        <span style="color: #ff7f50;">판매상품</span>
        <span>
          <Button v-on:click="searchSell()" label="조회" raised size="small" style="float:right;margin-left:5px;" />
          <Button v-on:click="click({type:'reg'})" label="상품등록" raised size="small" style="float:right;" />
        </span>
      </div>
      <div id="sell-order" class="card main">
        <div v-if="sellList === null || sellList === undefined || sellList.length === 0" class="no-result">등록한 상품이 없습니다</div>
        <div v-else >
          <DataTable v-model:expandedRows="expandedRows" :value="sellList" dataKey="IDX" stripedRows @rowExpand="onRowExpand">
            <Column expander style="width: 1rem;" />
            <Column field="IDX" header="IDX" />
            <Column field="" header="등록일" >
              <template #body="slotProps" >
                {{ this.api.getDateByFormat(slotProps.data.REG_DATE, 'YYYY.MM.DD') }}
              </template>
            </Column>
            <Column field="" header="상품명" >
              <template #body="slotProps" >
                {{ slotProps.data.NAME }}
              </template>
            </Column>
            <Column field="" header="상태" >
              <template #body="slotProps" >
                {{ slotProps.data.USE_YN === true ? '판매중' : '판매중지' }}
              </template>
            </Column>
            <Column field="" header="국가" >
              <template #body="slotProps" >
                <img style="width:25px;" :src="this.api.getCountryFlag(slotProps.data.COUNTRY)">
              </template>
            </Column>
            <template #expansion="slotProps">
              <table class="dynamic-table">
                <tr style="height:100px;">
                  <td style="width:100%;">
                    <table class="order_sub_table" style="table-layout:fixed;">
                      <tbody>
                        <tr style="height:30px;">
                          <td class="container_sub_style">
                            <div class="div_center">
                              <div style="min-width:100px;">상품명</div>
                              <div style="width:100%;">
                                <input ref="sellName" class="normalInput" type="text" :value="slotProps.data.NAME" />
                              </div>
                            </div>
                          </td>
                          <td class="container_sub_style">
                            <div class="div_center">
                              <div style="min-width:100px;" >국가</div>
                              <div style="width:100%;">
                                <select :value="slotProps.data.COUNTRY" class="normalInput" ref="sellCountry">
                                  <option value = "">선택</option>
                                  <option value = "홍콩">홍콩</option>
                                  <option value = "싱가포르">싱가포르</option>
                                  <option value = "프랑스">프랑스</option>
                                  <option value = "스페인">스페인</option>
                                  <option value = "이탈리아">이탈리아</option>
                                  <option value = "영국">영국</option>
                                  <option value = "독일">독일</option>
                                  <option value = "네덜란드">네덜란드</option>
                                  <option value = "일본">일본</option>
                                  <option value = "중국">중국</option>
                                  <option value = "미국">미국</option>
                                  <option value = "호주">호주</option>
                                  <option value = "오스트리아">오스트리아</option>
                                  <option value = "뉴질랜드" >뉴질랜드</option>
                                  <option value = "기타">기타</option>
                                </select>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr style="height:30px;">
                          <td class="container_sub_style">
                            <div class="div_center">
                              <div style="min-width:100px;">판매가</div>
                              <div style="width:100%;">
                                <input ref="sellPrice" class="normalInput" type="number" :value="slotProps.data.SELL_PRICE" />
                              </div>
                            </div>
                          </td>
                          <td class="container_sub_style">
                            <div class="div_center">
                              <div style="min-width:100px;" >상태</div>
                              <div style="width:100%;">
                                <select class="normalInput" :value="slotProps.data.USE_YN" ref="sellUseYn">
                                  <option value = true>판매중</option>
                                  <option value = false>판매중지</option>
                                </select>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="container_sub_style">
                            <div class="div_center">
                              <div style="min-width:100px;" >사진</div>
                              <div style="width:100%;">
                                <label ref="picName" for="inputFile" />
                                <input type="file" id="inputFile" @change="readFile($event, index)" style="display:none" accept="image/*" multiple>
                                <label class="link" type="button" for="inputFile">파일첨부</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
              <div class="sell-wrapper">
                <textarea ref="sellDesc" placeholder="상세설명" :value="slotProps.data.DESC"/>   
              </div>
              <div class="sell-wrapper" style="float:right;">
                <Button label="삭제" raised size="small" @click="click({type:'DELETE_TRADE', idx:slotProps.data.IDX, imgList:slotProps.data.IMG_LIST.split(',')})" style="margin-right:5px;" />
                <Button label="수정" raised size="small" @click="click({type:'UPDATE_TRADE', idx:slotProps.data.IDX})" />
              </div>
              <br><br>
              <div v-if="!(slotProps.data.IMG_LIST === undefined || slotProps.data.IMG_LIST === null)" class="marketImgPreview" >
                <div class="special-content margin10" :data-name="imgUrl.substring(0,100)" v-for="(imgUrl, index) in slotProps.data.IMG_LIST.split(',')" :key="index" >
                  <div class="reg-img-container">
                    <img v-if="imgUrl.length > 100" class="attachImg reg-img link" :src="'data:image/jpeg;base64,' + imgUrl.replace(/ /g, '')" @click="click({type:'removeImg', name:imgUrl.substring(0,100)})" style="max-height:70px;">
                    <img v-else class="attachImg reg-img link" :src="this.api.WSRV_POCKETBASE_URL + imgUrl.replace(/ /g, '')" @click="click({type:'removeImg', name:imgUrl.substring(0,100)})" style="max-height:70px;">
                    <div class="reg-img-title link" @click="click({type:'removeImg', name:imgUrl.substring(0,100)})" style="text-decoration:none;">x</div>
                  </div>
                </div>
              </div>
            </template>
          </DataTable>
        </div>
        <div class="div_center">
          <label @click="pagingSell('prev')" class="link">
            이전
          </label>
          <span style="margin:20px">{{ pageSell }}</span>
          <label @click="pagingSell('next')" class="link">
            다음
          </label>
        </div>
      </div>
      <div class="div_title">
        <span style="color: #ff7f50;">고객문의</span>
        <Button v-on:click="searchQuestion()" label="조회" raised size="small" />
      </div>
      <div id="question-order" class="card main">
        <div v-if="questionList === null || questionList === undefined || questionList.length === 0" class="no-result">고객문의가 없습니다</div>
        <div v-else v-for="(item, index) in questionList" :key="index">
          <button class="question-collapsible sell-collapsible text-cut" @click="collapseQuestion({idx:index})"> 
            <label>{{ this.api.getDateByFormat(item.REG_DATE, 'YYYY.MM.DD') }}</label> 
            <label style="margin-left: 20px;">{{ this.api.textCut(item.DESC, 30) }}</label> 
            <label style="float: right;margin-right:10px;" :style="item.REPLY === null ? 'color:red' : 'color:black'">{{ item.REPLY === null ? '답변전' : '답변완료' }}</label> 
          </button>
          <div class="sell-content question-content">
            <div class="sell-wrapper">
              <div id="sell-container" >
                <div class="mypage-wrapper" >
                  <div style="width: 150px;">
                    <img id='cart-img' class="margin10" :src="this.api.WSRV_POCKETBASE_URL + item.IMG_URL.replace(/ /g, '')" />
                  </div>
                  <div id="cart-container" >
                    <div class="mypage-cart-wrapper" > 
                      <p>
                        <img class="country-flag" :src="this.api.getCountryFlag(item.COUNTRY)" style="float:left; margin-right:10px;">
                        {{ item.COUNTRY }}
                        <label style="float:right;"><a :href='"https://geek9.kr/market?idx=" + item.TRADE_IDX' target="_blank">링크</a></label>
                      </p>

                      <p><label style="color:gray">[상품명]</label> {{ item.NAME }}</p>
                      <p>
                        <label style="color:gray">[가격]</label> <label style="float:right;">{{ Number(item.SELL_PRICE).toLocaleString() }}원</label><br>
                      </p>
                      <p v-if="!(item.SIZE === undefined || item.SIZE === '')"><label style="color:gray">[사이즈]</label> {{ item.SIZE }}</p>
                    </div>
                    <div class="mypage-cart-wrapper" >
                      <p>
                        <label style="float:right;" >{{ this.api.textCut(item.CLIENT_ID, 7).replace(/\./g, '*') }} </label>
                        <label style="color:gray">[문의자]</label> 
                      </p>
                      <p v-if="item.REPLY !== null">
                        <label style="float:right;" v-if="item.CHK_YN === true">읽음</label>
                        <label style="float:right;" v-if="item.CHK_YN === false">수신전</label>
                        <label style="color:gray">[수신여부]</label>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <label style="color:gray">[고객문의내용]</label> 
            <div class="sell-wrapper">
              <textarea ref="sellDesc" placeholder="고객문의내용" :value="item.DESC" disabled />   
            </div>
            <br>
            <label style="color:gray">[답변내용]</label> 
            <div class="sell-wrapper">
              <textarea class="replyDesc" placeholder="답변내용" :value="item.REPLY" />   
            </div>
            <input class="button" type="button" v-on:click="click({type:'UPDATE_TRADE_MSG', id:this.api.getCookies('id'), idx:item.IDX, replyIdx:index, clientId:item.CLIENT_ID})" value="답변하기" style="min-width:100px;" />
            <br>
            <div v-if="!(item.IMG_LIST === undefined || item.IMG_LIST === null)" class="marketImgPreview" >
              <div class="special-content margin10" :data-name="imgUrl.substring(0,100)" v-for="(imgUrl, index) in item.IMG_LIST.split(',')" :key="index" >
                <div class="reg-img-container">
                  <img v-if="imgUrl.length > 100" class="attachImg reg-img link" :src="'data:image/jpeg;base64,' + imgUrl.replace(/ /g, '')" @click="click({type:'removeImg', name:imgUrl.substring(0,100)})" style="max-height:70px;">
                  <img v-else class="attachImg reg-img link" :src="this.api.WSRV_POCKETBASE_URL + imgUrl.replace(/ /g, '')" @click="click({type:'removeImg', name:imgUrl.substring(0,100)})" style="max-height:70px;">
                  <div class="reg-img-title link" @click="click({type:'removeImg', name:imgUrl.substring(0,100)})" style="text-decoration:none;">x</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="divider" />
        <div class="div_center" >
          <label @click="pagingQuestion('prev')" class="link">
            이전
          </label>
          <span style="margin:20px">{{ pageQuestion }}</span>
          <label @click="pagingQuestion('next')" class="link">
            다음
          </label>
        </div>
      </div>
    </div>
    <login v-else @login="login" /> 
  </div>
  <div class="ad-container">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
</template>

<script>

// import { useMeta } from 'vue-meta'
import router from '@/router'
import { ref, inject } from 'vue'
import context from '@/components/common/Context.vue'
import policies from '@/components/common/policies.vue'
import login from '@/components/common/Login.vue'

export default {
  emits: ["setMenu", "login"],
  setup() {
    const setMeta = inject('setMeta')
    setMeta({
      title: 'GEEK9 > 마이페이지 > 판매',
      keywords: '직거래,판매,온라인셀러,판매자,글로벌셀러,중고거래,중고',
      description: '내가 가지고 있는 물건을 GEEK9에서 판매해보세요!',
      'og:title': 'GEEK9 > 마이페이지 > 판매',
      'og:description': '내가 가지고 있는 물건을 GEEK9에서 판매해보세요!',
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': 'https://geek9.kr/sell',
      'canonical': 'https://geek9.kr/sell'
    })
    // useMeta( {
    //   title: 'GEEK9 > 마이페이지 > 판매',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/sell'}],
    //   meta: [
    //     { vmid: 'charset_sell', charset: 'utf-8' },
    //     { vmid: 'http-equiv_sell', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_sell', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_sell', name: 'keywords', content:'직거래,판매,온라인셀러,판매자,글로벌셀러,중고거래,중고' },
    //     { vmid: 'viewport_sell', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_sell', name: 'description', content: '내가 가지고 있는 물건을 GEEK9에서 판매해보세요!' },
    //     { vmid: 'theme-color_sell', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_sell', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_sell', property: 'og:description', content: '내가 가지고 있는 물건을 GEEK9에서 판매해보세요!'},
    //     { vmid: 'og:title_sell', property: 'og:title', content: 'GEEK9 > 마이페이지 > 판매'},
    //     { vmid: 'og:image_sell', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_sell', property: 'og:url', content: 'https://geek9.kr/sell'}
    //   ]
    // })
    const isShow = ref(false)
    const expandedRows = ref({})
    function showModal () {
      isShow.value = true
    }
    function closeModal () {
      isShow.value = false
    }
    const cate = ref([
      { label: '마이페이지'},
      { label: '판매', route: '/sell' }
    ])
    function onRowExpand (event) {
      expandedRows.value = {[event.data.IDX]:true}
    }

    return {
      isShow, closeModal, onRowExpand, showModal, 
      cate, expandedRows
    }
  },
  components: {
    context, policies, login
  },
  unmounted() {
    this.emitter.off('sellReload', this.reload)
  },
  mounted() {
    this.emitter.on('sellReload', this.reload)
    this.api.setPage('sell')
    console.log(this.api.getCookies('page'))
    this.$emit("setMenu", {page:'sell'})
    if( (this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true)  ) {
      this.searchSell()
      this.searchQuestion()
    }
    window.scrollTo(0, 0)
  },
  data() { 
    return { 
      sellKey: 0,
      pageSell:1,
      pageQuestion:1,
      sellList: [],
      questionList: [],
      selectedMenu:0,
    }
  },
  methods: {
    login() {
      this.$emit('login')
    },
    async click(param) {
      let type = param.type
      if(type === 'DELETE_TRADE') {
        if(confirm("선택한 상품을 삭제할까요?")) {
          const idx = Number(param.idx)
          this.api.axiosGql(type, {type:type, clientId:this.api.getCookies('id'), idx:idx, imgList:param.imgList})
          .then( async (res) => {
            if(res.status === 200) {
              this.collapseAllSell()
              this.sellList = []
              alert('삭제되었습니다')
              this.searchSell()
            }
            else alert('다시 시도해주세요')
          })
        }
      } else if(type === 'UPDATE_TRADE_MSG') {
        const idx = param.idx
        const replyIdx = param.replyIdx
        const id = param.id
        const clientId = param.clientId

        const reply = document.getElementsByClassName('replyDesc')[replyIdx].value
        if(reply === '') alert('답변을 작성해주세요')
        else {
          await this.api.axiosGql(type, {type:type, id:id, idx:idx, reply:reply, clientId:clientId})
          .then( async (res) => {
            if(res.status === 200) {
              this.collapseAllQuestion()
              this.searchQuestion()
              alert('답변이 등록되었습니다')
            } else alert('다시 시도해주세요')
          })
          .catch( () => {
            alert('다시 시도해주세요')
          })
        }

      } else if(type === 'UPDATE_TRADE') {
        const name = this.$refs.sellName.value
        const country = this.$refs.sellCountry.value
        const sellPrice = this.$refs.sellPrice.value
        const desc = this.$refs.sellDesc.value
        const profileImgUrl = window.$cookies.get('img')
        const useYn = this.$refs.sellUseYn.value
        const idx = Number(param.idx)
        
        const attachImgList = document.getElementsByClassName('attachImg')
        let p = {}
        if(attachImgList.length > 0) {
          await this.uploadFile(attachImgList)
          .then( async (imgKeyList) => {
            p = {type:type, id:this.api.getCookies('id'), name:name, sellPrice:Number(sellPrice), idx:idx, country:country, desc:desc, profileImgUrl:profileImgUrl, useYn:useYn === 'true' ? 1 : 0, imgList:imgKeyList}
          })
        } else p = {type:type, id:this.api.getCookies('id'), name:name, sellPrice:Number(sellPrice), idx:idx, country:country, desc:desc, profileImgUrl:profileImgUrl, useYn:useYn === 'true' ? 1 : 0}
        
        await this.api.axiosGql(type, p)
        .then( async (res) => {
          if(res.status === 200) {
            this.collapseAllSell()
            this.sellList = []
            alert('수정되었습니다')
            this.searchSell()
          } else alert('다시 시도해주세요')
        })
      } else if(type === 'removeImg') {
        if(confirm("선택한 사진을 삭제할까요?")) {
          const name = param.name
          const specialContentList = document.getElementsByClassName('special-content')
          const attachImgList = document.getElementsByClassName('attachImg')
          const regImgTitleList = document.getElementsByClassName('reg-img-title')
          
          for(var j=0; j<specialContentList.length; j++) {
            const specialContent = specialContentList[j]
            if(name === specialContent.dataset.name) {
              const src = attachImgList[j].src
              attachImgList[j].remove()
              regImgTitleList[j].remove()
              specialContent.remove()
              if(src.indexOf('wsrv.nl') !== -1) this.api.axiosGql(type, {type:type, key:src.split('files/')[1].split('/')[1]})
              break
            }
          }
        }
      } else if(type === 'menu') {
        this.selectedMenu = param.value
        for(var i=0; i<2; i++) {
          let menu = document.getElementById('mypageMenu' + i)
          if(i === this.selectedMenu) menu.setAttribute("class", "active")
          else menu.setAttribute("class", "")
        }
        let p
        if(this.selectedMenu === 0) {
          type = 'SELECT_ORDER_LIKE'
          p = this.likePage
          this.clearCartInfo()
        } else {
          type = 'SELECT_ORDER_CART'
          p = -1
        }
        this.search({type:type, page:p})
      } else if(type === 'reg') {
        router.push({name:'market', params:{type:'menu', value:1}})
      }
    },
    reload(flag) {
      if(flag === 'dashboard') {
        this.$emit("setMenu", {page:'sell'})
        this.sellKey++
      }
    },
    pagingSell(type) {
      if(type === 'prev') {
        if(this.pageSell === 1) {
          this.$notify('첫 페이지입니다.')
          return
        } else this.pageSell -= 1
      } else this.pageSell += 1
      this.searchSell()
    },
    pagingQuestion(type) {
      if(type === 'prev') {
        if(this.pageQuestion === 1) {
          this.$notify('첫 페이지입니다.')
          return
        } else this.pageQuestion -= 1
      } else this.pageQuestion += 1
      this.searchQuestion()
    },
    searchSell() {
      this.api.axiosGql('SELECT_TRADE_BY_ID', {type:'SELECT_TRADE_BY_ID', id:this.api.getCookies('id'), page:this.pageSell})
      .then( async (res) => {
        const result = res.data
        if(result === null || result === 'null' || result === undefined || result.length === 0) {
          this.$notify('마지막 페이지입니다.')
          if(this.pageSell > 1) this.pageSell -= 1
        } else this.sellList = result
      })
    },
    searchQuestion() {
      this.api.axiosGql('SELECT_TRADE_QUESTION', {type:'SELECT_TRADE_QUESTION', id:this.api.getCookies('id'), page:this.pageSell})
      .then( async (res) => {
        const result = res.data
        if(result === null || result === 'null' || result === undefined || result.length === 0) {
          this.$notify('마지막 페이지입니다.')
          if(this.pageQuestion > 1) this.pageQuestion -= 1
        } else this.questionList = result
      })
    },
    async uploadFile(attachImgList) {
      let isSuccess = true
      const fileCnt = attachImgList.length
      const formData = new FormData()
      const type = 'UPLOAD_MARKET_IMGS'
      
      formData.append('service', 'geek9')
      formData.append('pbType', 'market')
      formData.append('type', type)
      formData.append('cnt', fileCnt)

      let imgKeyList = []

      for(let i=0; i<fileCnt; i++) {
        let dataUrl = attachImgList[i].src
        if(dataUrl.indexOf('wsrv.nl') === -1) {
          const arr = dataUrl.split(',')
          const mimeType = arr[0].match(/:(.*?);/)[1]
          const bstr = atob(arr[1])
          
          let n = bstr.length;
          const u8arr = new Uint8Array(n)
  
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
          }
          
          const file = new File([u8arr], i, { type: mimeType })
          formData.delete("file")  
          formData.append("file", file)  
          await this.api.axiosGql(type, formData)
          .then( async (res) => {
            if(res.data === undefined) isSuccess = false
            else imgKeyList.push(res.data.substring(0,100))
          })
        } else {
          const imgKey = dataUrl.split('files/')[1]
          imgKeyList.push(imgKey)
        }
      }
      return await new Promise( (resolve, reject) => {
        if(isSuccess) resolve(imgKeyList)
        else reject(undefined)
      })
    },
    collapseQuestion(param) {
      const sub_idx = param.idx
      const sub_menu_list = document.getElementById("question-order")
      let col_btn_list = sub_menu_list.getElementsByClassName("button")
      let col_con_list = sub_menu_list.getElementsByClassName("question-content")
      const btn = col_btn_list[sub_idx]
      const con = col_con_list[sub_idx]
      
      if(con === undefined) {
        this.collapseAllQuestion()
        const el = document.getElementById('question-order')
        el.scrollIntoView()
      } else {
        // open
        const btnClass = btn.getAttribute("class") 
        if(btnClass === 'question-collapsible sell-collapsible select' || btnClass === 'question-collapsible sell-collapsible select text-cut') {
          btn.setAttribute("class", "trade-collapsible sell-collapsible text-cut")
          btn.style.color = "black"
          btn.style.fontWeight = ""
          con.style.maxHeight = null
        } else {    // close
          this.collapseAllQuestion()
          btn.setAttribute("class", "question-collapsible sell-collapsible select text-cut")
          btn.style.fontWeight = "bold"
          con.style.maxHeight = con.scrollHeight + "px"
        }
      }
    },    
    collapseAllQuestion() {
      const sub_menu_list = document.getElementById("question-order")
      if(sub_menu_list !== null && sub_menu_list !== undefined) {
        let col_btn_list = sub_menu_list.getElementsByTagName("button")
        let col_con_list = sub_menu_list.getElementsByClassName("question-content")
        
        for(var i = 0; i < col_btn_list.length; i++) {
          let colBtn = col_btn_list[i]
          if(colBtn !== undefined) {
            colBtn.setAttribute("class", "question-collapsible sell-collapsible text-cut")
            colBtn.style.color = "black"
            colBtn.style.fontWeight = ""
          } 
          let tmpCon = col_con_list[i]
          if(tmpCon !== undefined ) tmpCon.style.maxHeight = null
        }
      }
    },
    collapseSell(param) {
      const sub_idx = param.idx
      const sub_menu_list = document.getElementById("sell-order")
      let col_btn_list = sub_menu_list.getElementsByClassName("trade-collapsible")
      let col_con_list = sub_menu_list.getElementsByClassName("trade-content")
      const btn = col_btn_list[sub_idx]
      const con = col_con_list[sub_idx]
      
      if(con === undefined) {
        this.collapseAllSell()
        const el = document.getElementById('sell-order')
        el.scrollIntoView()
      } else {
        // open
        const btnClass = btn.getAttribute("class") 
        if(btnClass === 'trade-collapsible sell-collapsible select' || btnClass === 'trade-collapsible sell-collapsible select text-cut') {
          btn.setAttribute("class", "trade-collapsible sell-collapsible text-cut")
          btn.style.color = "black"
          btn.style.fontWeight = ""
          con.style.maxHeight = null
        } else {    // close
          this.collapseAllSell()
          btn.setAttribute("class", "trade-collapsible sell-collapsible select text-cut")
          btn.style.fontWeight = "bold"
          con.style.maxHeight = con.scrollHeight + "px"
        }
      }
    },    
    collapseAllSell() {
      const sub_menu_list = document.getElementById("sell-order")
      if(sub_menu_list !== null && sub_menu_list !== undefined) {
        let col_btn_list = sub_menu_list.getElementsByClassName("trade-collapsible")
        let col_con_list = sub_menu_list.getElementsByClassName("trade-content")
        
        for(var i = 0; i < col_btn_list.length; i++) {
          let colBtn = col_btn_list[i]
          if(colBtn !== undefined) {
            colBtn.setAttribute("class", "trade-collapsible sell-collapsible text-cut")
            colBtn.style.color = "black"
            colBtn.style.fontWeight = ""
          } 
          let tmpCon = col_con_list[i]
          if(tmpCon !== undefined ) tmpCon.style.maxHeight = null
        }
      }
    },
    async readFile(event, index) {
      const fileCnt = event.target.files.length
      const attachedImgList = document.getElementsByClassName('attachImg')
      const totalFileCnt = attachedImgList.length + fileCnt
      if(totalFileCnt > 5) alert('사진은 최대 5개만 등록 가능합니다')
      else {
        const vm = this
        for(let i=0; i<fileCnt; i++) {
          const file = event.target.files[i]
          if(file) {
            const reader = new FileReader()
            reader.onload = async function (e) {
              // console.log(e.target.result)
              // console.log(index)
              vm.sellList[index].IMG_LIST += ',' + String(e.target.result).split(',')[1]
              //     :src="'//wsrv.nl/?url=' + this.api.POCKETBASE_URL + ':8090/api/files/' + imgUrl.replace(/ /g, '')" 
              //     @click="click({type:'removeImg', idx:index})" 
              //     style="max-height:70px;">
              // const img = document.createElement('img')
              // img.setAttribute('class', 'attachImg reg-img link')
              // vm.attachedImgList = document.getElementsByClassName('attachImg')
              // // img.setAttribute('data-idx', vm.attachedImgList.length)
              // img.src = e.target.result
              // img.style.maxHeight = '70px'
              // img.onclick = function() { 
              //   vm.click({type:'removeImg', idx:vm.attachedImgList.length})
              // }

              // const imgDelBtn = document.createElement('div')
              // imgDelBtn.setAttribute('class', 'reg-img-title link')
              // imgDelBtn.style.textDecoration = 'none'
              // imgDelBtn.innerHTML = 'x'
              // imgDelBtn.onclick = function() { 
              //   vm.click({type:'removeImg', idx:vm.attachedImgList.length})
              // }

              // const imgContainer = document.createElement('div')
              // imgContainer.setAttribute('class', 'reg-img-container')
              // imgContainer.appendChild(img)
              // imgContainer.appendChild(imgDelBtn)
              
              // const specialContent = document.createElement('div')
              // specialContent.setAttribute('class', 'special-content margin10')
              // specialContent.appendChild(imgContainer)
              // const bindedSpecialContent = document.getElementsByClassName('special-content')
              // specialContent.setAttribute('data-idx', bindedSpecialContent.length)

              // const marketImgPreview = document.getElementsByClassName('marketImgPreview')[0]
              // marketImgPreview.appendChild(specialContent)
            }
            reader.readAsDataURL(file)
            this.$notify('사진이 등록되었습니다')
          }
        }
      }
    }  
  }
}
</script>

<style scoped>
.sell-wrapper > textarea {
  width: 100%;
  height: 200px;
  resize: none;
  padding:10px;
  margin-top:15px;
  margin-bottom:5px;
}
.sell-wrapper {
  display:flex;
  align-items: center;
}

.select:after {
  content: "\2212";
}
@media screen and (min-width: 300px) {
  #sell-img {
    max-height:120px;
    max-width:80px;
  }
  #sell-container {
    width:100%;
  }
  .write-wrapper-wrapper {
    width:100%;
    vertical-align:middle;
  }
}
@media screen and (min-width: 850px) {
  #sell-img {
    max-height:150px;
    max-width:100px;
  }
  #sell-container {
    width:100%;
  }
  .write-wrapper-wrapper {
    width:50%;
    padding-left:20px;
    padding-right:20px;
    vertical-align:middle;
    border-right:1px dotted gray;
  }
}

</style>